import React from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { useConfig } from "@fitplan/context/lib-es/config";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Modal from "../../components/modal/Modal";
import Layout from "../../components/layout/layout";
import { AuthFormStrings } from "../../components/strings/AuthStrings";
import MikesPurchaseComplete from "../../components/landing/MikesPurchaseComplete";
import { ProductModal } from "../../lib/components/ProductModal";

const desktopBreakpoint = "1000px";

export const query = graphql`
  query ($languageISO: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: contentfulModules(
      type: { eq: "Header" }
      node_locale: { eq: $languageISO }
    ) {
      id
      items {
        ... on ContentfulItems {
          id
          link
          linkText
        }
      }
    }
    ribbon: contentfulModules(
      type: { eq: "Ribbon" }
      node_locale: { eq: $languageISO }
    ) {
      id
      heading
    }
    footer: allContentfulModules(
      filter: { type: { eq: "Footer" }, node_locale: { eq: $languageISO } }
    ) {
      nodes {
        id
        heading
        items {
          ... on ContentfulItems {
            id
            link
            linkText
          }
        }
      }
    }
  }
`;

const MikesChallengePage = ({ data: { ribbon, header, footer }, ...props }) => {
  const [show, setShow] = React.useState(false);
  const { imageBaseUrl } = useConfig();
  const { t } = useTranslation();

  const queryData = {
    homeSubscriptionBlock: {
      primaryButton: t("startFree"),
      secondaryButton: t("continue"),
    },
    startPage: {
      success: t("accountActivated"),
      button: t("downloadFitplan"),
      exit: t("alreadyHaveTheApp"),
    },
    subscribePage: {
      title: t("yourGoalsAreWaiting"),
      subtitle: t("tryFitplanFree"),
    },
  };

  return (
    <Layout
      header={header}
      ribbon={ribbon}
      footer={footer}
      hasBanner="no-banner"
    >
      <HeaderHero imageBaseUrl={imageBaseUrl}>
        <TitleHeader>{t("nothingCanStop")}</TitleHeader>
        <SubtitleHeader>
          <small>THE</small> {t("titanCrew")}
        </SubtitleHeader>
        <HeaderText>
          {t("mightStuckAtHome")} <i>{t("titan")}</i> {t("up")}
        </HeaderText>
      </HeaderHero>
      <MainContent>
        <GridContainer>
          <MainTitle>{t("fastChallengePackageIncludes")}</MainTitle>
          <VideoContainer>
            <VideoPlaceholder imageBaseUrl={imageBaseUrl} />
          </VideoContainer>
          <DesktopBuy>
            <BuyContent setShow={setShow} />
          </DesktopBuy>
          <NumberedContainer>
            <NumberSide>1</NumberSide>
            <TextSide>{t("lifetimeAccess")}</TextSide>
            <NumberSide>2</NumberSide>
            <TextSide>{t("membershipToTitanMentor")}</TextSide>
            <NumberSide>3</NumberSide>
            <TextSide>{t("nutritionGuide")}</TextSide>
            <NumberSide>4</NumberSide>
            <TextSide>{t("purchaseMealsDelivery")}</TextSide>
            <NumberSide>5</NumberSide>
            <TextSide>{t("accessToTrainers")}</TextSide>
          </NumberedContainer>
          <MobileBuy>
            <BuyContent setShow={setShow} />
          </MobileBuy>
          <CTAContainer>
            <YearOfTitan>
              <SectionYoT>{t("yearOfTitan")}</SectionYoT>
              <SectionYoT>{t("commitToday")}</SectionYoT>
            </YearOfTitan>
            <MobileCallToAction onClick={() => setShow(true)}>
              {t("letsDoThis")}
            </MobileCallToAction>
          </CTAContainer>
        </GridContainer>
      </MainContent>
      <Modal open={show}>
        <AuthFormStrings data={queryData}>
          <ProductModal
            product={{
              code: "ch_2020mikechallenge",
              name: "Mike's challenge",
              description: "Buy Mike's Challenge Bro",
              amount: 7500,
            }}
            language="en"
          >
            <MikesPurchaseComplete />
          </ProductModal>
        </AuthFormStrings>
      </Modal>
    </Layout>
  );
};

const BuyContent = ({ setShow }) => {
  const { t } = useTranslation();
  return (
    <>
      <BuyHeader>{t("oneTimePurchase")}</BuyHeader>
      <CallToAction onClick={() => setShow(true)}>
        {t("letsDoThis")}
      </CallToAction>
      <BuyText>{t("purchaseIncludesLifetime")}</BuyText>
    </>
  );
};

const GridContainer = styled.div`
  text-align: center;
  display: inline-grid;
  grid-template-columns: 100vw;
  grid-template-areas:
    "right"
    "buy"
    "title"
    "left"
    "cta";

  @media (min-width: ${desktopBreakpoint}) {
    grid-template-columns: calc(50vw - 490px) 450px 80px 450px calc(
        50vw - 490px
      );
    grid-template-areas:
      " title title title title title "
      " . left . right . "
      " buy buy buy buy buy "
      " cta cta cta cta cta ";
  }
`;

const CallToAction = styled.div`
  font-family: Barlow Condensed;
  font-weight: 600;
  font-size: 21px;
  text-align: center;
  text-transform: uppercase;
  min-width: 5em;
  margin: 1.75em auto;
  padding: 0.75em 1.75em;
  border: none;
  border-radius: 1.5em;
  background-image: linear-gradient(79deg, #00bd98, #28d95a);
  box-shadow: 0 8px 16px 10px rgba(0, 0, 0, 0.05);
  display: inline-block;
  cursor: pointer;
`;

const MobileCallToAction = styled(CallToAction)`
  visibility: visible;
  @media (min-width: ${desktopBreakpoint}) {
    visibility: hidden;
  }
`;

const HeaderHero = styled.div`
  background-image: url(${(props) =>
    props.imageBaseUrl}/web/landing/mikes-challenge/hero.png?tr=w-1200);
  background-size: cover;
  background-position: top center;
  height: 100vw;
  box-shadow: inset 0 -100px 100px 0px #000;

  @media (min-width: ${desktopBreakpoint}) {
    height: 60vw;
    box-shadow: inset 0 -100px 100px 0px rgba(0, 0, 0, 0.75);
  }
`;

const Title = styled.section`
  color: #fff;
  font-family: Barlow Condensed, Barlow, sans-serif;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px #000;
  text-transform: uppercase;
  position: relative;
  padding-left: 10vw;

  @media (min-width: ${desktopBreakpoint}) {
    text-align: right;
    text-shadow: 0.2vw 0.2vw #000;
    padding-right: 10vw;
  }
`;
const TitleHeader = styled(Title)`
  font-size: 9vw;
  padding-top: 50vw;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 4.2vw;
    padding-top: 22vw;
  }
`;

const SubtitleHeader = styled(Title)`
  font-style: italic;
  font-size: 10vw;
  small {
    font-size: 80%;
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 4.8vw;
  }
`;

const HeaderText = styled.div`
  color: #fff;
  font-family: Barlow Condensed, Barlow, sans-serif;
  font-size: 3.66vw;
  text-align: center;
  padding: 2vw 10vw 0 10vw;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 2vw;
    text-align: right;
    padding: 4vw 10vw 0 52vw;
  }
`;

const MainContent = styled.div`
  color: #fff;
  font-family: Barlow;
  background-color: #1d222b;
  padding-top: 40px;

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: 0;
  }
`;

const MainTitle = styled.div`
  grid-area: title;
  font-family: Barlow Condensed, Barlow, sans-serif;
  font-size: 6vw;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  padding: 40px 2.5vw 0;
  background-color: rgb(17, 18, 19);

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 2.4em;
    padding: 40px 0;
    background-color: transparent;
  }
`;

const InlineDiv = styled.div`
  text-align: center;
  margin: 0 auto;
  display: block;
  position: relative;
  vertical-align: top;

  @media (min-width: ${desktopBreakpoint}) {
    display: inline-block;
  }
`;

const VideoContainer = styled(InlineDiv)`
  grid-area: right;
  text-align: center;
  width: 100vw;

  @media (min-width: ${desktopBreakpoint}) {
    width: 450px;
  }
`;

const VideoPlaceholder = styled.div`
  background-image: url(${(props) =>
    props.imageBaseUrl}/web/landing/mikes-challenge/video.jpg?tr=w-800);
  background-size: cover;
  background-position: top center;
  width: 100vw;
  height: 100vw;
  padding: 0;

  @media (min-width: ${desktopBreakpoint}) {
    width: 100%;
    height: auto;
    padding: 50% 0;
    margin-top: 40px;
  }
`;

const NumberedContainer = styled(InlineDiv)`
  width: 100vw;
  padding: 40px 0;
  background-color: rgb(17, 18, 19);

  @media (min-width: ${desktopBreakpoint}) {
    width: 450px;
    grid-area: left;
    background-color: transparent;
  }
`;

const CTAContainer = styled.div`
  width: 100vw;
  grid-area: cta;
`;

const NumberSide = styled(InlineDiv)`
  font-size: 48px;
  font-weight: 800;
  width: 60px;
  margin-top: -8px;
`;

const TextSide = styled(InlineDiv)`
  font-size: 20px;
  text-align: center;
  width: 80vw;
  margin: 0 10vw 20px;

  @media (min-width: ${desktopBreakpoint}) {
    text-align: justify;
    width: 390px;
    margin: 0 0 20px;
  }
`;

const DesktopBuy = styled.div`
  grid-area: buy;
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    display: block !important;
  }
`;

const MobileBuy = styled.div`
  grid-area: buy;
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    display: none !important;
  }
`;

const YearOfTitan = styled.div`
  font-family: Barlow Condensed, Barlow, sans0serif;
  font-weight: bold;
  font-size: 30px;
  margin-top: 40px;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 2.5vw;
    margin-top: 10px;
  }
`;

const SectionYoT = styled.section`
  text-align: center;
  width: 100vw;
  margin-right: 0;
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    width: auto;
    margin-right: 0.5vw;
    display: inline-block;
  }
`;

const BuyHeader = styled.div`
  font-family: Barlow Condensed, Barlow, sans-serif;
  font-size: 6vw;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px auto;
  padding: 20px 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 30px;
    padding: 0;
  }
`;

const BuyText = styled.div`
  font-family: Barlow, sans-serif;
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto 40px;
  padding: 0 10vw;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: 16px;
    margin: 20px auto 50px;
    padding: 0;
  }
`;

export default MikesChallengePage;
